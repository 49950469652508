import React, { useState } from "react"
import banner from "../images/banner.png"
import { Link } from "gatsby"

export const LogoBrand = () => (
  <img src={banner} alt="Jeffrey - A virtual personal assistent." />
)
export const TextBrand = () => <h1 className="is-size-2">Jeffrey.</h1>

const Navbar = ({ brand }) => {
  const [active, setActive] = useState(false)
  return (
    <nav className="navbar" role="navigation" aria-label="main navigation" data-aos="fade-down">
      <div className="container">
        <div className="navbar-brand">
          <div className="navbar-item">
            <Link to="/">{brand()}</Link>
          </div>
          <a
            role="button"
            className="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            onClick={() => setActive(!active)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div className={"navbar-menu" + (active ? " is-active" : "")}>
          <div className="navbar-end">
            {/* <Link className="navbar-item" to="/for-universities">
              For Universities
            </Link> */}
            <a className="navbar-item" href="https:/m.me/getjeffreyai">
              Try now
            </a>
            <a className="navbar-item" href="#contact">Contact</a>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
